import React from "react";
import { Helmet } from "react-helmet";

const title = (props) => {
  let t;
  if (props.title) {
    t = `${props.title} | McClean.ie`;
  } else {
    t = "McClean.ie";
  }
  return t;
};

const SEO = (props) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title(props)}</title>
      <meta name="description" content={props.description || ""} />
    </Helmet>
  );
};

export default SEO;
