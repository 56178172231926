import React, { Component } from "react";
import axios from "axios";
//import { orderBy } from "lodash";
import LazyLoad from "react-lazyload";

import SEO from "../seo";

import "./moviesWatched.scss";

class MoviesWatched extends Component {
  state = { movies: [] };

  componentDidMount() {
    axios
      .get(
        "https://api.airtable.com/v0/app9Jjc5uroFke7M4/Movies%20I've%20Watched?view=Grid%20view",
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`
          }
        }
      )
      .then(res => {
        //const movies = orderBy(res.data.records, ["createdTime"], ["desc"]);
        const movies = res.data.records;
        this.setState({ movies });
      });
  }

  createRating(number = 0) {
    let backgroundColor = "#444";

    if (number <= 4) {
      backgroundColor = "255, 0, 0";
    }

    if (number > 4 && number < 7) {
      backgroundColor = "255, 205, 0";
    }

    if (number >= 7) {
      backgroundColor = "29,255,7";
    }

    return (
      <div
        style={{
          backgroundColor: `rgba(${backgroundColor}, .250)`,
          borderRadius: ".25rem"
        }}
      >
        <div
          style={{
            width: `${number * 10}%`,
            backgroundColor: `rgba(${backgroundColor})`,
            height: "1rem",
            borderRadius: ".25rem"
          }}
        ></div>
      </div>
    );
  }

  render() {
    return (
      <div className="movies-watched__container">
        <SEO
          title="Movies I've watched recently"
          description="Micro movie reviews of things I've watch... mostly good, some bad!"
        />
        <div className="movies-watched">
          {this.state.movies.length > 0 &&
            this.state.movies.map(movie => {
              if (movie.fields["Published"]) {
                return (
                  <div key={movie.id} className="movies-watched__movie">
                    {movie.fields["Image"] && (
                      <LazyLoad height={200}>
                        <img
                          className="movies-watched__image"
                          src={
                            `https://avlcjnrdko.cloudimg.io/width/357/x/` +
                            movie.fields["Image"][0].url
                          }
                          alt={movie.fields["Movie Name"]}
                          //height={200}
                          width={357}
                        />
                      </LazyLoad>
                    )}
                    <div className="movies-watched__inner">
                      <h2 className="movies-watched__heading">
                        {movie.fields["Movie Name"]}
                      </h2>
                      <div className="movies-watched__rating-bar">
                        {this.createRating(movie.fields["Rating"])}
                      </div>
                      <p>{movie.fields["Notes"]}</p>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    );
  }
}
export default MoviesWatched;
