import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./app.scss";

import HomePage from "../homepage";
import MoviesWatched from "../movies-watched";
import Contact from "../contact";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/movies">
            <MoviesWatched />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route path="/*">
            <main>404</main>
          </Route>
        </Switch>
        <footer />
      </Router>
    );
  }
}

export default App;
