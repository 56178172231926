import React, { Component } from "react";
import "./contact.scss";

class Contact extends Component {
  render() {
    return (
      <div className="contact">
        <div className="contact__container">
          <h1 class="contact__heading">This is a story all about how</h1>
          <h2 class="contact__sub-heading">
            My life got flipped turned upside down
          </h2>
          <p>
            And I'd like to take a minute so just sit right there, and I tell
            you how I became the prince of a town called Bel-Air
          </p>
        </div>
      </div>
    );
  }
}

export default Contact;
