import React, { Component } from "react";
import SEO from "../seo";
import "./homepage.scss";
import ProfilePicture from "./20190712_100615.jpg";

class Home extends Component {
  render() {
    var year = new Date().getFullYear();
    return (
      <div className="home">
        <SEO
          title="Paul McClean, web developer, Dundalk, Ireland"
          description="Paul McClean, web development, design, UX, software architecture in Dundalk, Co. Louth, Ireland"
        />
        <article className="home__container">
          <img
            className="home__profile-picture"
            src={ProfilePicture}
            alt="Paul"
          ></img>
          <h1 className="home__heading">Hello, I am Paul McClean</h1>
          <h2 className="home__sub-heading">
            Senior web developer based in Ireland specializing in performant
            user interface &amp; web application development, user experience
            design and agile best practises. Particular fondness for Jamstack /
            React / Astro and Next.js.
          </h2>
          <p>
            Currrently working in retail e-commerce, I have developed sites,
            applications and business critical integrations within the financial
            technology, broadcast media, government and charity sectors.
          </p>
          <p>
            I can be contacted via my{" "}
            <a href="https://www.linkedin.com/in/paulmcclean/">LinkedIn page</a>
            .
          </p>
          <p>
            <a href="https://letterboxd.com/wutangpaul/films/by/date/">
              Movies
            </a>{" "}
            I've watched recently and tracked on the awesome Letterboxd.
          </p>
          <p>
            <a href="https://www.flickr.com/photos/paulmcclean/">Photos</a> I've
            taken over the years. I currently own a Lumix G85 camera and my
            trusty old Canon EOS 550d, which took most of these photos. I keep a
            small collection of modern lenses and cheapo bonkers Russian lenses
            from the 80's which I love.
          </p>
          <p>
            <small>All content © {year} - Paul McClean</small>
          </p>
        </article>
      </div>
    );
  }
}

export default Home;
